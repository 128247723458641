.isoUser {
    margin-right: 0;
    cursor: pointer;
    line-height: normal;
    position: relative;
    display: inline-block;
}

.isoUser .isoImgWrapper {
    width: 40px;
    height: 40px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    background-color: rgb(251, 251, 251);
    border-radius: 50%;
}

.isoUser .isoImgWrapper img {
    height: 100%;
    object-fit: cover;
}

.isoUser .isoImgWrapper .userActivity {
    width: 10px;
    height: 10px;
    display: block;
    background-color: rgb(126, 211, 33);
    position: absolute;
    bottom: 0px;
    right: 3px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50%;
}
