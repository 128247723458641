:root {
    --btn-primary-color: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)
        ),
        #3a86ff;
}

.humucontent {
}

.humuToggler {
    border: 1px solid #fc6d2b !important;
}

.humuToggler .navbar-toggler-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28252, 109, 43, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e');
}

.humuFullContent {
    width: 100%;
}

.humuLeftContent {
    padding: 0 31px;
    background-color: #f1f3f6;
}
@media only screen and (min-width: 992px) {
    .humuLeftContent {
        max-height: calc(100vh - 70px);
        overflow: auto;
    }
}

.humuTopBar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.humuTopBar .nav-link {
    color: #000;
    text-decoration: none;
    margin-top: 10px;
}
.humuTopBar .nav-link img {
    margin-top: -4px;
}

.humuLeftFormCollapse {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.humuLeftFormExpand {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.humuRightContent {
    background-color: #fff;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 31px;
    max-height: calc(100vh - 70px);
}

@media only screen and (min-width: 992px) {
    .humuRightContent {
        max-height: calc(100vh - 70px);
        overflow: auto;
    }
}

.humuRightFormExpand {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.humuRightFormCollapse {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.humuaccordion-body {
    padding: 1.5rem 0px;
}

.humu-accordion-button {
    border: none;
    font-weight: bold;
    background-color: #dee6f2;
}

.humu-accordion-item {
    border: none;
    background-color: transparent;
}

.humu-accordion-item .accordion-button:not(.collapsed) {
    color: #000;
}

.humu-accordion-item .form-label {
    margin-bottom: 0;
}

.humu-accordion-item .humu-accordion-button.collapsed {
    background-color: var(--bs-accordion-active-bg);
}

.humu-accordion-item .accordion-header {
    -webkit-box-shadow: 0px 10px 13px -7px #cfcfcf,
        13px 13px 3px 3px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #cfcfcf, 13px 13px 3px 3px rgba(0, 0, 0, 0);
}

.humu-button-group .btn-outline-primary {
    background-color: #fff;
}

.humu-button-group .btn {
    font-size: 0.8rem;
}

button.btn.btn-primary {
    background-color: #3a86ff;
}

button.btn.btn-primary.btn-tagging {
    background-color: #3a86ff;
    background-image: url('../images/icon/taggingIconWhite.svg');
    background-repeat: no-repeat;
    padding-left: 30px;
    vertical-align: middle;
    background-position: left 10px center;
}

button.btn.btn-outline-primary.btn-content {
    background-image: url('../images/icon/contentIconBlue.svg') !important;
    background-repeat: no-repeat;
    padding-left: 30px;
    vertical-align: middle;
    background-position: left 10px center;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
    background-image: url('../images/icon/contentIconWhite.svg');
    background-repeat: no-repeat;
    padding-left: 30px;
    vertical-align: middle;
    background-position: left 10px center;
}

.modal button {
    font-size: 0.8em;
    margin-right: 10px;
}

button.btn.btn-primary.me-3 {
    background: var(--btn-primary-color);
    width: 113px;
    height: 36px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}

button.btn.btn-outline-primary.me-3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    width: 113px;
    height: 36px;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 110px;
    height: -37px;
    background-image: url('../images/icon/taggingIconBlue.svg');
    background-repeat: no-repeat;
    padding-left: 30px;
    vertical-align: middle;
    background-position: left 10px center;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 110px;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
}

#content-btn:hover {
    background-image: url('../images/icon/contentIconWhite.svg') !important;
    background-repeat: no-repeat;
    padding-left: 30px;
    vertical-align: middle;
    background-position: left 10px center;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
    background-image: url('../images/icon/taggingIconWhite.svg');
    background-repeat: no-repeat;
    padding-left: 30px;
    vertical-align: middle;
    background-position: left 10px center;
}

.css-1pi9rcw-MuiButtonBase-root-MuiPaginationItem-root {
    border: 1px solid #d9d9d9 !important;
}

.css-1pi9rcw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    color: #fff;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        #3a86ff;
}

.md-editor-error {
    color: red;
}

.md-editor-container-error {
    text-align: left;
    border-radius: 3px;
    padding-bottom: 10px;
    position: relative;
    color: var(--color-fg-default);
    box-shadow: 0 0 0 1px red, 0 0 0 red, 0 1px 1px red !important;
    background-color: var(--color-canvas-default);
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}
.addexistingModal-noRecord {
    margin-left: 30%;
    padding-top: 30px;
}

.humu-image-upload-box {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    padding: 2em 0;
}

.imageBox {
    border: 1px dashed #d9d9d9;
    background-color: #fff;
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageBox img {
    max-height: 398px;
}

.upload-image-button-box {
    text-align: center;
    width: 100%;
    height: 100%;
    display: block;
}

.upload-image-button-box .text {
    display: block;
    margin-top: 2em;
}

@media only screen and (min-width: 992px) {
    .upload-image-button-box .text {
        margin-top: 150px;
    }
}

.imgwrap img {
    display: block;
}

.col-center-border {
    border-right: 1px solid #d9d9d9;
}

.humu-chat-box {
    height: 500px;
    overflow: auto;
    padding: 15px;
}

.humu-chat-img {
    width: 40px;
    height: 40px;
}

.chat-item {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 1em;
}

.chat-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.chat-item-header h6 {
    display: inline-block;
}

.chat-timestamp {
    color: #999;
    font-size: 0.8em;
    display: inline-block;
}

.chat-item-body {
    font-size: 0.8em;
    padding: 0;
}

.card {
    -webkit-box-shadow: 0px 10px 13px -7px #cfcfcf,
        13px 13px 3px 3px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #cfcfcf, 13px 13px 3px 3px rgba(0, 0, 0, 0);
}

.card.error {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.card-body.error {
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color);
    border: 1px solid red !important;
}

.card-header,
.card-footer {
    background-color: #fff;
}

.flagImg {
    height: 20px;
    width: 28px;
}

.assignedBox {
    height: 200px;
    padding: 10px;
    overflow: auto;
}

.assignedImg {
    width: 30px;
    height: 30px;
}

.versionsBox {
    height: 222px;
    padding: 10px;
    margin-top: 10px;
    padding-top: 10px;
    overflow: auto;
}

.rsw-editor {
    width: 100%;
}

.humuImageCard .btn-primary {
    text-align: center;
}

.imgSelector {
    overflow: auto;
    height: 550px;
    padding-right: 1em;
}

.imgSelector .card {
    box-shadow: none;
    border: none;
}

.imgSelector .card-body {
    padding: 0;
    border: 0;
    text-shadow: none;
}

.row.mb-4 .col-sm-12.col-lg-4.mb-sm-4 .card.error {
    border-color: #f65656;
    position: static;
}

.row.mb-4 .col-sm-12.col-lg-4.mb-sm-4 .card .card-body .card {
    border-color: rgba(0, 0, 0, 0.175);
}

.humuSelectedImg {
    width: 100%;
    height: 550px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.humuSelectedImg img {
    width: 100%;
}

.humuImageModal .btn-primary {
    width: 10em;
}

.modal button {
    font-size: 0.8em;
}

.input-group .btn-empty-search {
    font-size: 1em;
    border-left: 1px solid #ced4da;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    color: #999;
    margin-right: 0px;
}

.input-group .btn-empty-search:hover {
    border-color: #ced4da;
    border-right: 1px solid rgba(0, 0, 0, 0);
    color: #000;
}

.search-group input {
    border-left: 0px;
}

.modalTable {
    width: 100%;
}

.modalTable thead {
    background-color: #f7f7f7;
    font-weight: bold;
}

.modalTable thead th {
    padding: 0.5em 1em;
}

.modalTable tbody {
    cursor: pointer;
}

.modalTable tbody td {
    border-bottom: 1px solid #ced4da;
    font-size: 0.8rem;
    padding: 2em 1em;
    vertical-align: middle;
}

.modalTable tbody tr:last-child td {
    border-bottom: 0px;
}

.modalTable tbody td:first-child {
    width: 40%;
}

.modalTable tbody td:last-child {
    width: 60%;
}

/*Pop Up Boxes*/
.modal-title.h4 {
    font-size: 1rem;
}

.modal-footer-dismiss button {
    background-color: #f65656;
    padding-left: 2rem;
    padding-right: 2rem;
    border: 1px solid #f65656;
}

.humuErrorModalBody p strong {
    color: #000;
}

.humuErrorModalBody p {
    color: #999;
}

.humuEexportNudgeBody p {
    color: #000;
}

.humuExportNudgeModal .modal-footer {
    display: flex;
    justify-content: space-between;
}

.AddRationaleModal .scroll-box {
    max-height: 500px;
    overflow: auto;
}

.AddRationaleModal .small {
    color: #999;
}

/***** Sign In *****/
.sign-in-left {
    /*background: url('/assets/images/work.jpg') no-repeat center center;*/
    background-size: cover;
    align-items: center;
}

.sign-in-right .humuLoginContent {
    padding: 70px 50px;
}

.humuSignInLogo img {
    width: 150px;
    height: auto;
}

.form-control {
    font-size: 0.8rem;
}

.humuLoginContent form {
    font-size: 0.8rem;
}

.humuLoginContent form .btn-primary {
    font-size: 0.8rem;
}

.humuLoginButtonBox {
    border-top: 1px dashed #d8d8d8;
}

.humuLoginButtonBox button {
    width: 100%;
}

.btn-GooglePlus {
    background-color: #dd4b39;
    color: #fff;
}

.btn-Option2 {
    background-color: #e14615;
    color: #fff;
}

.btn-Option3 {
    background-color: #ffca28;
    color: #fff;
}

.humuForgotPW a {
    font-size: 0.8rem;
    text-decoration: none;
    color: #797979;
}

.humuForgotPW a:hover {
    text-decoration: underline;
}

.form-control {
    border: 1px solid #e9e9e9;
}

.humu-card-table td {
    padding: 0.5em;
    font-size: 0.85em;
}

.humu-card-table td:first-child {
    border-right: 1px solid #e9e9e9;
}

.humu-card-table th {
    color: #aaa;
    font-weight: normal;
    min-width: 80px;
    text-align: center;
    font-size: 0.8em;
}

.humu-card-table th:first-child {
    min-width: 100px;
}

.humu-card-table thead {
    border-bottom: 1em solid #fff;
}

.humu-card-radio {
    vertical-align: middle;
}

.humu-card-title {
    padding: 0.5em;
    background-color: #e7f1ff;
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.tagging-hr-top {
    border: 1px solid #d9d9d9;
    margin-top: 32px;
}

.tagging-hr-bottom {
    border: 1px solid #d9d9d9;
    margin-top: -25px;
}

.invalid-error {
    color: red;
    margin-top: -12px;
}

.invalid-field {
    border: 1px solid red !important;
}

.selected-image {
    border: 1px solid orange !important;
}

.btn-remove {
    font-size: small !important;
    color: red !important;
}

.btn-remove:hover {
    font-size: small;
    color: red;
    border: none;
}

/*** Nudge List***/
.nudge-list .btn-primary {
    font-size: 0.8rem;
}

.nudge-list .humuLeftContent {
    background-color: #fff;
}

.nudge-list h1 {
    font-size: 1.5rem;
}

.nudge-list thead {
    background-color: #f7f7f7;
    font-weight: bold;
    font-size: 0.9rem;
}

.nudge-list .nudge-table {
    font-size: 0.8rem;
}

.nudge-list .p-behavior {
    width: 40%;
    max-width: 50%;
}

.nudge-list tfoot {
    background-color: #f7f7f7;
    border-bottom: #fff;
}

.nudge-table .pagination .page-link {
    font-size: 0.8rem;
    color: #000;
}

.rsw-ce[contenteditable='false'] {
    background-color: #e9ecef;
}

.rbt-token-label {
    display: inline-block;
}

.rbt-token-remove-button {
    float: right;
    background: none !important;
}

.impliesRadioBox {
    /* min-width:82px; */
    justify-content: center;
    padding: 0 15px;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.impliesRadioGroup {
    margin-left: 10px;
}

@media (min-width: 1403px) and (max-width: 1684px) {
    .impliesRadioGroup {
        margin-left: 0px;
    }
    .impliesRadioBox {
        padding-left: 5px;
        padding-right: 5px;
    }
    .humu-card-table th {
        min-width: 0;
    }
}

@media (min-width: 1400px) and (max-width: 1515px) {
    .humu-card-table td:first-child {
        width: 80px;
    }
}
@media (min-width: 1400px) and (max-width: 1402px) {
    .impliesRadioGroup {
        margin-left: 0px;
    }
    .impliesRadioBox {
        padding-left: 3px;
        padding-right: 3px;
    }
    .humu-card-table th {
        min-width: 0;
    }
}

@media (min-width: 1400px) and (max-width: 1515px) {
    .humu-card-table td:first-child {
        width: 80px;
    }
}

@media (min-width: 1400px) and (max-width: 1402px) {
    .impliesRadioGroup {
        margin-left: 0px;
    }
    .impliesRadioBox {
        padding-left: 3px;
        padding-right: 3px;
    }
    .humu-card-table th {
        min-width: 0;
    }
}

.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 0 0 0.2rem #c3dbfc;
}

.humuForgotPw h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0px 0px 7px;
    color: rgb(50, 51, 50);
}

.humuForgotPw p {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    margin: 0px;
    color: rgb(151, 151, 151);
}

.btn-full {
    width: 100%;
}

.nav-link-height {
    height: 25% !important;
}

select option[value=''],
select option:not([value]) {
    display: none;
}

.previewLinkStyle {
    font-size: 17px;
    font-weight: bold;
    align-self: center;
    float: right;
    padding: 10px;
}
.rationales-top-box-table .card-body {
    padding-left: 10px;
    padding-right: 10px;
}
.rationales-top-box-table table {
    width: 100%;
}
.rationales-top-box-table thead {
    background-color: #fff;
}

.rationales-top-box-table td {
    vertical-align: top;
}

.rationales-top-box-table .table-rationales {
    max-width: 250px;
}

.heading {
    margin-top: 20px;
    margin-bottom: 10px;
}
