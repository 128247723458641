
.avatarimagecontainer {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    background-color: rgb(251, 251, 251);
    border-radius: 50%;
}

.avatarimage {
    height: 100%;
    object-fit: cover;
}

.dropdownavatar {
    padding: 7px 0px;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 220px;
    min-width: 160px;
    flex-shrink: 0;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 10px;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.ant-popover-arrow {
    position: absolute;
    display: block;
    width: 8.48528137px;
    height: 8.48528137px;
    background: transparent;
    border-style: solid;
    border-width: 4.24264069px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}

.humuTopBar .nav-link .avatarimage {
}

.humuTopBar .nav-link {
    height: 46px;
}

.arrow-container {
    width: 100%;
    position: relative;
    background-color: green;
    display: flex;
    justify-content: right;
}

.avatar-arrow {
    position: absolute;
    top: -19px;
    right: 25px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-top: 0.5px solid gray;
    border-left: 0.5px solid gray;
    margin-right: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}

.ant-popover {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1030;
    font-weight: 400;
    white-space: normal;
    text-align: left;
    cursor: auto;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.ant-popover-arrow {
    position: absolute;
    display: block;
    width: 8.48528137px;
    height: 8.48528137px;
    background: transparent;
    border-style: solid;
    border-width: 4.24264069px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    top: 6px;
    border-color: #fff transparent transparent #fff;
    -webkit-box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
    box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
    right: 0 !important;
    left: auto !important;
}
.check {
    right: 45px !important;
    left: auto !important;
    top: 65px !important;
    border-radius: 0 !important;
    border-width: 0 !important;
    background-color: transparent !important;
    height: -20px !important;
}

.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    right: 16px !important;
}

.cqVVdn.isoUserDropdown {
    padding: 7px 0px;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 220px;
    min-width: 160px;
    flex-shrink: 0;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 10px;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.cqVVdn {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    margin: -12px -16px;
    width: 360px;
    min-width: 160px;
    flex-shrink: 0;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 10px;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    cursor: pointer;
}

.cqVVdn.isoUserDropdown .isoDropdownLink {
    font-size: 13px;
    color: rgb(89, 89, 89);
    line-height: 1.1;
    padding: 7px 15px;
    text-decoration: none;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.ant-popover-inner-content {
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.65);
}

.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    right: 16px;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    top: 6px;
    border-color: #fff transparent transparent #fff;
    -webkit-box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
    box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
}

.cqVVdn.isoUserDropdown {
    padding: 7px 0px;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 220px;
    min-width: 160px;
    flex-shrink: 0;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 10px;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.no-default {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
